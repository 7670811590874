import * as Products from '@avcan/constants/products'
import * as URLUtils from '@avcan/utils/url'

export const HAZARD_TIME = 'T20:00:00Z'

export function formatDateForQuery(date) {
    // Date is in the format of yyyy-MM-dd
    // The forecasts shown are those that were in place at 11:59 AM Pacific on the day the forecast expires.
    // 12 PM PDT becomes 8 PM UTC, so we need to add 20 hours to the date to get the correct forecast.
    try {
        const initialDate = new Date(date + HAZARD_TIME)

        return initialDate.toISOString()
    } catch (error) {
        console.error(error)

        return null
    }
}

export function formatDateFromQuery(date) {
    // Date is in the format of yyyy-MM-dd
    // The forecasts shown are those that were in place at 11:59 AM Pacific on the day the forecast expires.
    // 12 PM PDT becomes 8 PM UTC, so we need to add 20 hours to the date to get the correct forecast.
    try {
        return new Date(date + HAZARD_TIME)
    } catch (error) {
        console.error(error)

        return null
    }
}

export function createPath(product, id, prefix = '/map') {
    return URLUtils.buildPath(isAnalysis(product) ? prefix : null, PATHS.get(product), id)
}

export function createSearchParams(product, id) {
    const params = new URLSearchParams()

    params.set('panel', createPath(product, id))

    return params
}

export function isAnalysis(product) {
    return ANALYSIS_PRODUCTS.has(product)
}

export function isObservations(product) {
    return OBSERVATION_PRODUCTS.has(product)
}

export function computeProductParams(pathname) {
    const [path, id] = pathname
        .split('/')
        .filter(Boolean)
        .filter(chunk => chunk !== 'map')

    return {
        product: getProductFromPath(path),
        id,
    }
}

export function getProductFromPath(path) {
    return REVERSE_PATHS.get(path)
}

export function getPathFromProduct(product) {
    return PATHS.get(product)
}

const PATHS = new Map([
    [Products.FORECAST, 'forecasts'],
    [Products.WEATHER_STATION, 'weather-stations'],
    [Products.MOUNTAIN_CONDITIONS_REPORT, 'mountain-conditions-reports'],
    [Products.ACCIDENT, 'fatal-accidents'],
    [Products.MOUNTAIN_INFORMATION_NETWORK, 'mountain-information-network-submissions'],
    [Products.ICE_CLIMB, 'ice-climb'],
])
const REVERSE_PATHS = new Map(Object.entries(Object.fromEntries(PATHS)).map(([k, v]) => [v, k]))
const ANALYSIS_PRODUCTS = new Set([Products.FORECAST])
const OBSERVATION_PRODUCTS = new Set([
    Products.WEATHER_STATION,
    Products.MOUNTAIN_CONDITIONS_REPORT,
    Products.ACCIDENT,
    Products.MOUNTAIN_INFORMATION_NETWORK,
    Products.ICE_CLIMB,
])
