import { create } from 'zustand'

const MapStore = create(set => ({
    returnFocusId: null,

    setReturnFocusId: focus => {
        set({ returnFocusId: focus })
    },
}))

export const useReturnFocusId = () => MapStore(state => state.returnFocusId)
export const useSetReturnFocusId = () => MapStore(state => state.setReturnFocusId)
