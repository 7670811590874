import * as React from 'react'
import PropTypes from 'prop-types'
import mapboxgl from 'mapbox-gl'
import * as Hooks from 'hooks'
import { useRouter } from 'next/router'

const MapStateContext = React.createContext()

Provider.propTypes = {
    children: PropTypes.element.isRequired,
}

export function Provider({ children }) {
    const value = useContextValue()

    return <MapStateContext.Provider value={value}>{children}</MapStateContext.Provider>
}

export function useMapState() {
    return React.useContext(MapStateContext)
}

export function useOptions() {
    const { zoom, center } = useMapState()
    const router = useRouter()

    return React.useMemo(() => {
        const { searchParams } = new URL(router.asPath, document.location.origin)

        switch (true) {
            case searchParams.get('bounds') === 'newfoundland':
                router.replace(router.pathname, null, { shallow: true })

                return {
                    bounds: NEWFOUNDLAND,
                    fitBoundsOptions,
                }
            case zoom.value === null && center.value === null:
                return {
                    bounds: BOUNDS,
                    fitBoundsOptions,
                }
            default:
                return {
                    zoom: zoom.value || 4.3,
                    center: center.value || [-125, 55],
                }
        }
    }, [])
}

export function useMapEvents() {
    const { zoom, center } = useMapState()
    const errors = useErrors()

    return React.useMemo(() => {
        return {
            zoomend(event) {
                const map = event.target

                zoom.set(map.getZoom())
            },
            moveend(event) {
                const map = event.target

                center.set(map.getCenter())
            },
            error(event) {
                errors.add(ERRORS.MAP, event.error)
            },
        }
    }, [])
}

export function getBounds({ longitude, latitude }) {
    const { LngLatBounds } = mapboxgl
    const point = [longitude, latitude]

    return AREAS_BOUNDS.find(bounds => LngLatBounds.convert(bounds).contains(point)) || WESTERN
}

// Utils
function useContextValue() {
    const [zoom, setZoom] = Hooks.useSessionStorage('zoom')
    const [center, setCenter] = Hooks.useSessionStorage('center')
    const errors = useErrors()
    const [productId, setProductId] = React.useState(null)
    const [primaryDrawerOpened, openPrimaryDrawer, closePrimaryDrawer] = Hooks.useBoolean(false)

    return React.useMemo(
        () => ({
            zoom: {
                value: zoom,
                set: setZoom,
            },
            center: {
                value: center,
                set: setCenter,
            },
            errors,
            productId,
            setProductId,
            primaryDrawerOpened,
            openPrimaryDrawer,
            closePrimaryDrawer,
        }),
        [
            zoom,
            setZoom,
            center,
            setCenter,
            errors,
            productId,
            primaryDrawerOpened,
            openPrimaryDrawer,
            closePrimaryDrawer,
        ]
    )
}
function useErrors() {
    const ERRORS = React.useRef(new Set())
    const [value, setValue] = React.useState(new Map())

    return React.useMemo(
        () => ({
            value,
            total: Array.from(value.values()).reduce(count, 0),
            add(type, error) {
                if (ERRORS.current.has(error)) {
                    return
                }

                setValue(() => {
                    ERRORS.current.add(error)

                    if (!value.has(type)) {
                        value.set(type, new Set())
                    }

                    value.get(type).add(error)

                    return new Map(value)
                })
            },
            clear() {
                ERRORS.current.clear()
                setValue(new Map())
            },
        }),
        [value]
    )
}

// Constants
// TODO Perhaps we could reuse product constants
export const ERRORS = {
    FORECAST: Symbol('fx'),
    WEATHER_STATION: Symbol('wx'),
    MOUNTAIN_CONDITIONS_REPORT: Symbol('mcr'),
    INCIDENT: Symbol('incident'),
    ADVISORY: Symbol('advisory'),
    MOUNTAIN_INFORMATION_NETWORK: Symbol('min'),
    MAP: Symbol('map'),
}
const WESTERN = [-136.5, 48, -112.9, 61]
const NORTHERN = [-141, 57.5, -126.4, 63.4]
const EASTERN = [-80, 43, -50.1, 52.6]
const NEWFOUNDLAND = [-61.7, 46.5, -51.7, 52]
const AREAS_BOUNDS = [WESTERN, NORTHERN, EASTERN]

export const BOUNDS = WESTERN

const fitBoundsOptions = {
    padding: 25,
}

// Utils
function count(count, { size }) {
    return count + size
}
