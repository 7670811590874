import * as React from 'react'

import useSWR from 'swr'
import * as turf from '@turf/helpers'
import * as Products from '@avcan/constants/products'
import * as MINUtils from '@avcan/utils/products/min'
import * as TypesUtils from '@avcan/utils/products/min/types'

import * as Forecasts from './forecasts'
import * as MCR from './mcr'
import * as MIN from './min'
import * as Weather from './weather'
import * as Index from './'
import { useLayer } from 'contexts/layers'
import { getSPAWArea } from 'clients/spaws'

// TODO Look at creating an API for that! The usage of ething function affects bundle size!

export function Provider({ data, children }) {
    const value = React.useMemo(() => {
        return {
            data: new Map([
                ['weather.stations', data.weather.stations],
                ['forecasts.metadata', data.forecasts.metadata],
                ['forecasts.areas', data.forecasts.areas],
                ['min.reports', data.min.reports],
                ['mcr.reports', data.mcr.reports],
                ['accidents', data.accidents],
                ['spaw.area', data.spaw.area],
            ]),
        }
    }, [data])

    return <context.Provider value={value}>{children}</context.Provider>
}

export async function getData(locale) {
    const [
        forecastAreas,
        forecastMetadata,
        weatherStations,
        minReport,
        mcrReports,
        accidents,
        spawArea,
    ] = await Promise.all([
        Forecasts.areas(locale),
        Forecasts.metadata(locale),
        Weather.stations(),
        MIN.reports(),
        MCR.reports(),
        Index.accidents(locale),
        getSPAWArea(locale),
    ])

    return {
        forecasts: {
            metadata: forecastMetadata,
            areas: forecastAreas,
        },
        weather: {
            stations: weatherStations,
        },
        min: {
            reports: minReport,
        },
        mcr: {
            reports: mcrReports,
        },
        accidents,
        spaw: {
            area: spawArea,
        },
    }
}

// Getters
export function useWeatherStations() {
    return useDataContext('weather.stations')
}

export function useForecastMetadata() {
    return useDataContext('forecasts.metadata')
}

export function useForecastAreas() {
    return useDataContext('forecasts.areas')
}

export function useMountainInformationNetworkReports() {
    // Would like to apply a filter to the layer or better to the source, but none working!
    // https://github.com/mapbox/mapbox-gl-js/issues/2613
    const reports = useDataContext('min.reports')
    const product = Products.MOUNTAIN_INFORMATION_NETWORK
    const key = product + ':reports'
    // To make sure we do not have stale data. Typical case is right after a user submits observations
    let { data } = useSWR(key, () => MIN.reports(), {
        fallbackData: reports,
        revalidateOnMount: true,
    })

    // TODO: Remove this when we have proper mocking in cypress
    if (process.env.NEXT_PUBLIC_MOCK_API === 'true') {
        data = reports
    }
    const layer = useLayer(product)
    const { days, types } = layer.filters

    return React.useMemo(() => {
        const params = { age: days, ...TypesUtils.split(types) }

        return MINUtils.filterFeatureCollection(data, params)
    }, [data, days, types])
}

export function useMountainInformationNetworkReport(id) {
    const { features } = useMountainInformationNetworkReports()
    const key = 'min/reports/' + id

    return useFeature(features, () => MIN.report(id), id, key)
}

export function useMountainConditionReports() {
    return useDataContext('mcr.reports')
}

export function useSPAWArea() {
    return useDataContext('spaw.area')
}

export function useMountainConditionReport(id) {
    const { features } = useMountainConditionReports()
    const key = 'mcr/reports/' + id

    return useFeature(features, () => MCR.report(id), id, key)
}

export function useAccidents() {
    return useDataContext('accidents')
}

// Utils & constants
const context = React.createContext()
const EMPTY_FEATURE_COLLECTION = turf.featureCollection([])
function useFeature(features, fetch, id, key) {
    const hasFeature = features.some(feature => feature.id === id)
    const { data } = useSWR(id ? (hasFeature ? null : 'map/sources/' + key) : null, fetch)

    return data ? turf.featureCollection([data]) : EMPTY_FEATURE_COLLECTION
}
function useDataContext(path) {
    const { data } = React.useContext(context)

    return data.get(path)
}
