import * as turf from '@turf/helpers'
import Client from 'clients/forecasts'
import * as Products from '@avcan/constants/products'

export function metadata(locale) {
    return Client.metadata(locale)
}

export async function areas(locale) {
    const [metadata, areas] = await Promise.all([Client.metadata(locale), Client.areas(locale)])
    const features = metadata.map(createFeature).filter(Boolean)
    function createFeature(item) {
        const area = areas.features.find(area => area.properties.id === item.area.id)

        if (!area) {
            return
        }

        const { product } = item
        const extra = {
            product: Products.FORECAST,
            id: product.id,
        }

        return {
            ...area,
            properties: {
                ...area.properties,
                ...extra,
            },
        }
    }

    return turf.featureCollection(features)
}
