import * as React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Body, Navbar, Close } from 'components/page/drawer'
import * as Components from 'components/page/drawer/layers'
import { useLayer } from 'contexts/layers'
import * as Products from '@avcan/constants/products'
import * as Layers from 'constants/products/layers'
import * as Icons from 'components/icons'
import { Dropdown, DropdownFromOptions, Option } from 'components/controls'
import Shim from 'components/Shim'
import * as Messages from 'constants/min/messages'
import { DAYS } from '@avcan/constants/products/min/days'
import { useUserSettings } from 'contexts/usersettings'

Menu.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
}

export default function Menu({ isArchive = false, onCloseClick }) {
    const reportTypeOptions = Messages.useReportTypeOptions()
    const placeholder = (
        <FormattedMessage description="Layout main map menu" defaultMessage="Report type" />
    )
    const {
        colourblindModeEnabled,
        toggleColourBlindMode,
        hoverOverDangerRatingEnabled,
        toggleHoverOverDangerRatingMode,
    } = useUserSettings()

    return (
        <React.Fragment>
            <Navbar>
                <Close onClick={onCloseClick} />
            </Navbar>
            <Body>
                <Shim horizontal>
                    {isArchive === false ? (
                        <Components.LayerSet
                            title={
                                <FormattedMessage
                                    description="Layout main map menu"
                                    defaultMessage="Map Layers"
                                />
                            }>
                            <Layer id={Products.FORECAST} />
                            <Layer id={Products.MOUNTAIN_INFORMATION_NETWORK}>
                                <Dropdown name="days">
                                    {DAYS.map(amount => (
                                        <Option key={amount} value={amount}>
                                            <FormattedMessage
                                                description="Layout main map menu"
                                                defaultMessage="{amount, plural, one {# day} other {# days}}"
                                                values={{ amount }}
                                            />
                                        </Option>
                                    ))}
                                </Dropdown>
                                <DropdownFromOptions
                                    options={reportTypeOptions}
                                    name="types"
                                    placeholder={placeholder}
                                />
                            </Layer>
                            <Layer id={Products.WEATHER_STATION} />
                            <Layer id={Products.ACCIDENT} />
                            <Layer id={Products.MOUNTAIN_CONDITIONS_REPORT} />
                            <Layer id={Products.ICE_CLIMB} />
                            <Layer id={Products.CLOSURE_ZONE} />
                        </Components.LayerSet>
                    ) : null}
                    <Components.LayerSet
                        title={
                            <FormattedMessage
                                defaultMessage="Settings"
                                description="Layouts main map menu"
                            />
                        }>
                        <label style={styles.flex}>
                            <FormattedMessage
                                defaultMessage="Hover-over danger rating"
                                description="Layouts main map menu"
                            />
                            <input
                                type="checkbox"
                                checked={hoverOverDangerRatingEnabled}
                                onChange={toggleHoverOverDangerRatingMode}
                            />
                        </label>
                        <label style={styles.flex}>
                            <FormattedMessage
                                defaultMessage="Colorblind-safe mode"
                                description="Layouts main map menu"
                            />
                            <input
                                type="checkbox"
                                checked={colourblindModeEnabled}
                                onChange={toggleColourBlindMode}
                            />
                        </label>
                    </Components.LayerSet>
                </Shim>
            </Body>
        </React.Fragment>
    )
}

// Util components
Layer.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.element,
}

function Layer({ id, children }) {
    const intl = useIntl()
    const { visible, disabled, filters, toggle, setFilterValue } = useLayer(id)
    const title = intl.formatMessage(Layers.TitleMessages[id])
    const Icon = ICONS.get(id)

    return (
        <Components.Layer
            title={title}
            icon={<Icon />}
            visible={visible}
            disabled={disabled}
            onClick={toggle}>
            {React.Children.map(children, input => {
                const { name } = input.props

                return React.cloneElement(input, {
                    value: filters[name],
                    onChange(value) {
                        setFilterValue(name, value)
                    },
                })
            })}
        </Components.Layer>
    )
}

// Constants
const ICONS = new Map([
    [Products.FORECAST, Icons.Forecast],
    [Products.MOUNTAIN_INFORMATION_NETWORK, Icons.MountainInformationNetwork],
    [Products.MOUNTAIN_CONDITIONS_REPORT, Icons.MountainConditionsReport],
    [Products.WEATHER_STATION, Icons.WeatherStation],
    [Products.ACCIDENT, Icons.FatalAccident],
    [Products.CLOSURE_ZONE, Icons.ClosureZone],
    [Products.ICE_CLIMB, Icons.IceClimbingAtlas],
])
const styles = {
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1em 0',
    },
}
