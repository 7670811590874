import { FormattedMessage, useIntl } from 'react-intl'
import { MCR } from '@avcan/constants/urls'
import * as Assets from 'utils/assets'
import styles from './MountainConditionsReport.module.css'

export default function Footer() {
    const intl = useIntl()
    const src = Assets.images('logos', 'acmg.png')
    const alt = intl.formatMessage({
        defaultMessage: 'Association of Canadian Mountain Guides (ACMG) logo',
    })

    return (
        <div className={styles.Footer}>
            <a href={MCR} title="Mountain Conditions Reports" target="mcr">
                <img src={src} alt={alt} />
            </a>
            <div>
                <FormattedMessage
                    description="Layout products/mcr/Footer"
                    defaultMessage="For more photos and more information about the professional mountain guide who created this report visit: <link></link>."
                    values={{
                        link() {
                            const { hostname } = new URL(MCR)

                            return (
                                <a href={MCR} target="mcr">
                                    {hostname}
                                </a>
                            )
                        },
                    }}
                />
            </div>
        </div>
    )
}
