import useSWR from 'swr'

import * as URLUtils from '@avcan/utils/url'
import AvCanGeocoderClient from '@avcan/utils/api/clients/geocoder'

const base = process.env.NEXT_PUBLIC_GEOCODER_API_URL
const apiKey = process.env.NEXT_PUBLIC_GEOCODER_API_KEY

const client = new AvCanGeocoderClient(base, apiKey)

export default client

export function useSearchTerm(term) {
    const key = URLUtils.buildPath(base, 'v1', 'search', term)
    return useSWR(term ? key : null, () => client.search(term))
}
