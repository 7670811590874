import { subDays } from 'date-fns'
import Client from 'clients/min'
import * as MINUtils from '@avcan/utils/products/min'
import { DateParam } from '@avcan/utils/params'
import { MAX as DAYS } from '@avcan/constants/products/min/days'

export async function reports() {
    const fromdate = DateParam.format(subDays(new Date(), DAYS))
    const params = { fromdate, pagesize: 1000 }
    const { items } = await Client.submissions(params)

    return MINUtils.createFeatureCollection(items.data)
}

export async function report(id) {
    const report = await Client.submission(id)

    return MINUtils.createFeature(report)
}
