import LOCALE from '@avcan/constants/locales'
import { Client, Interceptors } from '../../../fetch'
import * as URLUtils from '../../../url'

export default class MountainInformationNetworkSchemasClient {
    constructor(base, key) {
        const url = URLUtils.buildPath(base, 'min')

        this.client = new Client(url, new Interceptors.CacheKey(), new Interceptors.APIKey(key))
    }
    getData(version, locale) {
        return this.get(locale, version, 'data')
    }
    getForm(version, locale) {
        return this.get(locale, version, 'ui', 'form')
    }
    getDisplay(version, locale) {
        return this.get(locale, version, 'ui', 'display')
    }
    get(locale = LOCALE, version, ...rest) {
        const path = [locale, 'schema']

        if (version) {
            path.push('id', version)
        } else {
            path.push(...rest)
        }

        return this.client.get(path)
    }
}
