import * as Turf from '@turf/helpers'
import { ACCIDENT as product } from '@avcan/constants/products'

export function createFeature({ uid: id, data }) {
    const { location, title } = data
    const properties = { product, id, title }

    return Turf.point([location.longitude, location.latitude], properties, { id })
}

export function createFeatureCollection(data) {
    return Turf.featureCollection(data.map(createFeature))
}
