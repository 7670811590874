import clsx from 'clsx'
import css from './dialog.module.css'

// TODO Should be using <dialog>
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog

export default function Dialog({ children, open }) {
    const className = clsx({
        [css.Dialog]: true,
        [css.Open]: open,
    })

    return (
        <section className={className}>
            <div className={css.Inner}>{children}</div>
        </section>
    )
}

export function Header({ children }) {
    return <header className={css.Header}>{children}</header>
}

export function Footer({ children }) {
    return <footer className={css.Footer}>{children}</footer>
}

export function Body({ children }) {
    return <div className={css.Body}>{children}</div>
}
