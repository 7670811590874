import * as Products from '@avcan/constants/products'
import { useRouter } from 'next/router'

import Forecast from './products/Forecast'
import MountainInformationNetwork from './products/MountainInformationNetwork'
import WeatherStation from './products/WeatherStation'
import FatalAccident from './products/FatalAccident'
import MountainConditionsReport from './products/MountainConditionsReport'
import { useMenu } from 'contexts/menu'
import Drawer, { LEFT, RIGHT } from 'components/page/drawer'
import { Menu as Icon } from 'components/icons'
import Button, { SUBTILE } from 'components/button'
import Content from './Menu'
import { useSecondaryDrawer } from './hooks'
import styles from './drawers.module.css'
import { useMapState } from 'contexts/map/state'
import { useSetReturnFocusId } from 'stores/MapStore'
import IceClimb from './products/IceClimb'

export function Menu({ isArchive = false }) {
    const { opened, close } = useMenu()

    return (
        <Drawer side={LEFT} width={300} open={opened} onCloseClick={close}>
            <Content isArchive={isArchive} onCloseClick={close} />
        </Drawer>
    )
}

export function ToggleMenu() {
    const { toggle } = useMenu()

    return (
        <Button className={styles.Menu} onClick={toggle} kind={SUBTILE}>
            <Icon />
        </Button>
    )
}

export function Primary({ isArchive }) {
    const { productId, primaryDrawerOpened, closePrimaryDrawer } = useMapState()
    const { asPath, push } = useRouter()
    const setReturnFocusId = useSetReturnFocusId()

    const handleClose = () => {
        // Remove query params for lat and lng
        const url = new URL(asPath, window.location.origin)
        url.searchParams.delete('lat')
        url.searchParams.delete('lng')

        push(url.toString(), undefined, {
            shallow: true,
        })
        closePrimaryDrawer()

        setReturnFocusId(productId)
    }

    if (!productId) {
        return null
    }

    return (
        <Drawer side={RIGHT} open={primaryDrawerOpened}>
            <Forecast id={productId} close={handleClose} isArchive={isArchive} />
        </Drawer>
    )
}

export function Secondary() {
    const { opened, product, id } = useSecondaryDrawer()

    return (
        <Drawer open={opened} side={LEFT}>
            <ProductComponent product={product} id={id} />
        </Drawer>
    )
}

// Components
function ProductComponent({ product, ...props }) {
    if (ProductComponents.has(product)) {
        const Component = ProductComponents.get(product)

        return <Component {...props} />
    }

    return null
}
const ProductComponents = new Map([
    [Products.FORECAST, Forecast],
    [Products.MOUNTAIN_INFORMATION_NETWORK, MountainInformationNetwork],
    [Products.WEATHER_STATION, WeatherStation],
    [Products.ACCIDENT, FatalAccident],
    [Products.MOUNTAIN_CONDITIONS_REPORT, MountainConditionsReport],
    [Products.ICE_CLIMB, IceClimb],
])
