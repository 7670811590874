import client, { Predicates } from './index'
import { addDays, subDays } from 'date-fns'
import * as PredicateUtils from './utils/predicates'
import { startOfSeason, endOfSeason } from '@avcan/utils/date'
import * as Types from '@avcan/prismic/types'
import { DateParam } from '@avcan/utils/params'

export function get(uid, locale) {
    const predicate = PredicateUtils.uid(Types.FATAL_ACCIDENT, uid)

    return client.queryFirst(predicate, { locale })
}

export async function getAll(locale) {
    const field = PredicateUtils.my(Types.FATAL_ACCIDENT, 'dateOfAccident')
    const predicates = [
        Predicates.dateAfter(field, DateParam.format(subDays(startOfSeason(), 1))),
        Predicates.dateBefore(field, DateParam.format(addDays(endOfSeason(), 1))),
    ]
    const options = { pageSize: 100, locale }
    const { results } = await client.query(predicates, options)

    return results
}
