import { useState } from 'react'

import { useSearchTerm } from 'clients/geocoder'
import { useBoolean } from 'hooks'

export const useMapGeocoder = (init, customSearch) => {
    const initialSelectIndex = customSearch ? undefined : 0
    const [term, setTerm] = useState(init ? init : '')
    const terms = useSearchTerm(term)
    const hasTerm = Boolean(term)
    const [active, activate, deactivate] = useBoolean(false)
    const [selectedIndex, setSelectedIndex] = useState(initialSelectIndex)
    const [termCount, setTermCount] = useState(0)

    const onFocus = () => {
        if (!hasTerm) {
            activate()
        }
    }

    const onKeyDown = (event, selectHandler) => {
        // Down
        if (event.keyCode === 40) {
            event.preventDefault()
            setSelectedIndex(calculateSelectedIndex(selectedIndex, termCount, 1))
        }

        // Up
        if (event.keyCode === 38) {
            event.preventDefault()
            setSelectedIndex(calculateSelectedIndex(selectedIndex, termCount, 0))
        }

        // Enter
        if (event.keyCode === 13) {
            event.preventDefault()
            if (hasTerm) {
                selectHandler(terms.data[selectedIndex])
                deactivate()
                !customSearch && setSelectedIndex(0)
            }
        }

        // Escape
        if (event.keyCode === 27) {
            deactivate()
        }
    }

    return {
        active,
        activate,
        deactivate,
        term,
        terms,
        hasTerm,
        termCount,
        selectedIndex,
        setSelectedIndex,
        setTerm,
        setTermCount,
        onFocus,
        onKeyDown,
    }
}

// Helpers
const calculateSelectedIndex = (currentIndex, max, add = true) => {
    // Add or subtract 1
    let newIndex = currentIndex + 1 * (add ? 1 : -1)
    // Set to 0 or max if out of bounds
    if (newIndex > max - 1) {
        newIndex = 0
    }
    if (newIndex < 0) {
        newIndex = max - 1
    }
    return newIndex
}
