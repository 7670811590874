import Link from 'next/link'
import { FormattedMessage } from 'react-intl'

import { Expand } from 'components/icons'
import { GRAY_DARKER } from 'constants/colors'

export const ExpandButton = ({ id }) => {
    return (
        <div style={styles.container}>
            <Link href={'/forecasts/' + id} style={styles.link}>
                <FormattedMessage defaultMessage="Full Screen" description="Forecast sidebar" />
                <Expand color={GRAY_DARKER} />
            </Link>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '0.5em',
    },
    link: {
        color: GRAY_DARKER,
        textTransform: 'uppercase',
        fontSize: '0.85em',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        gap: '0.5em',
    },
}
