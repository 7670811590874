import * as React from 'react'
import { useRouter } from 'next/router'
import { useWindowSize } from 'hooks'
import * as ProductUtils from 'utils/product'
import * as Context from 'contexts/map'

export function useSecondaryDrawer() {
    const router = useRouter()
    const { asPath } = router

    return React.useMemo(() => {
        const { searchParams, pathname } = new URL(asPath, document.location.origin)

        if (!searchParams.has('panel')) {
            return BLANK_PARAMS
        }

        const panel = searchParams.get('panel')
        const { product, id } = ProductUtils.computeProductParams(panel)

        return {
            product,
            id,
            opened: ProductUtils.isObservations(product),
            close() {
                router.push(pathname, undefined, { shallow: true })
            },
        }
    }, [asPath])
}

export function useFlyTo() {
    const map = Context.useMap()
    const offset = useMapOffset()

    return React.useCallback(
        (center, zoom = 13) => {
            map?.flyTo({
                center,
                zoom,
                offset,
            })
        },
        [map, offset]
    )
}

export function useFitBounds() {
    const map = Context.useMap()
    const offset = useMapOffset()

    return React.useCallback(
        bounds => {
            map?.fitBounds(bounds, {
                offset,
                padding: 75,
                speed: 2.5,
            })
        },
        [map, offset]
    )
}

export function useMapOffset() {
    const { query } = useRouter()
    const { lat, lng } = query || {}
    const secondary = useSecondaryDrawer()
    const size = useWindowSize()
    const width = Math.min(500, size.width)

    return React.useMemo(() => {
        let x = 0

        // HACK: Let's assume that if lat and lng are present, the drawer is most likely opened
        if (lat && lng && size.width > WIDTH_TO_START_ADD_MAP_OFFSET) {
            x -= width / 4
        }

        if (secondary.opened && size.width > WIDTH_TO_START_ADD_MAP_OFFSET) {
            x += width / 4
        }

        return [x, 0]
    }, [lat, lng, size.width, secondary.opened, width])
}

// Constants
const WIDTH_TO_START_ADD_MAP_OFFSET = 750
const BLANK_PARAMS = {
    opened: false,
}
