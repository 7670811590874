import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'
import { LoadingSpinner } from './LoadingSpinner/LoadingSpinner'

// Via: https://github.com/vercel/next.js/discussions/11484#discussioncomment-3032984
export const RouterReady = ({ children }) => {
    const router = useRouter()
    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (router.isReady) {
            setReady(true)
        }
    }, [router.isReady])

    if (!ready) {
        return (
            <div style={style.empty}>
                <LoadingSpinner />
            </div>
        )
    }

    return <>{children}</>
}

const style = {
    empty: {
        display: 'block',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}
