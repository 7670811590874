import useSWRImmutable from 'swr/immutable'
import Client from '@avcan/utils/api/clients/mcr'
import * as Config from './config'

const client = new Client(Config.legacy)

export default client

export function useReport(id) {
    const key = ['mcr', 'reports', id]

    return useSWRImmutable(key, () => client.report(id))
}
