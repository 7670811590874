import { defineMessages } from 'react-intl'
import * as Products from '@avcan/constants/products'

export const NameMessages = defineMessages({
    [Products.FORECAST]: {
        description: 'Product name',
        defaultMessage: 'Avalanche Forecast',
    },
    [Products.OFFSEASON]: {
        description: 'Product name',
        defaultMessage: 'Off Season Message',
    },
    [Products.MOUNTAIN_INFORMATION_NETWORK]: {
        description: 'Product name',
        defaultMessage: 'Mountain Information Network',
    },
    [Products.MOUNTAIN_CONDITIONS_REPORT]: {
        description: 'Product name',
        defaultMessage: 'Mountain Conditions Report',
    },
    [Products.ACCIDENT]: {
        description: 'Product name',
        defaultMessage: 'Fatal Avalanche Incident',
    },
    [Products.WEATHER_STATION]: {
        description: 'Product name',
        defaultMessage: 'Weather Station',
    },
    [Products.SPAW]: {
        description: 'Product name',
        defaultMessage: 'Special Public Avalanche Warning',
    },
})
