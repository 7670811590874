import React from 'react'
import Icon from './Icon'

export const Info = ({ inverse = false, ...props }) => {
    return (
        <Icon {...props} viewBox="0 0 209 209">
            <g>
                <path
                    d="M96.892,66.067l15.3,-0l-0,-14.85l-15.3,-0l-0,14.85Zm-11.55,91.05l38.4,-0l-0,-11.25l-12,-0l-0,-64.65l-27.15,-0l-0,11.25l12.6,-0l-0,53.4l-11.85,-0l-0,11.25Z"
                    style={{
                        fill: inverse ? '#fff' : '#000',
                    }}
                />
                <circle
                    cx="104.167"
                    cy="104.167"
                    r="95.833"
                    style={{
                        fill: 'none',
                        stroke: inverse ? '#fff' : '#000',
                        strokeWidth: '16.67px',
                    }}
                />
            </g>
        </Icon>
    )
}
