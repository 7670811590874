import Client from 'clients/mcr'
import * as MCRUtils from '@avcan/utils/products/mcr'

export async function reports() {
    const reports = await Client.reports()

    return MCRUtils.createFeatureCollection(reports)
}
export async function report(id) {
    const report = await Client.report(id)

    return MCRUtils.createFeature(report)
}
