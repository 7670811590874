import * as React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import * as Types from '@avcan/constants/products/min/types'

export const Names = defineMessages({
    [Types.QUICK]: {
        description: 'MIN Report type name',
        defaultMessage: 'Quick',
    },
    [Types.AVALANCHE]: {
        description: 'MIN Report type name',
        defaultMessage: 'Avalanche',
    },
    [Types.SNOWPACK]: {
        description: 'MIN Report type name',
        defaultMessage: 'Snowpack',
    },
    [Types.WEATHER]: {
        description: 'MIN Report type name',
        defaultMessage: 'Weather',
    },
    [Types.INCIDENT]: {
        description: 'MIN Report type name',
        defaultMessage: 'Incident',
    },
})

export const QuickReportTags = defineMessages({
    snowConditions: {
        description: 'MIN Report tags for snow conditions',
        defaultMessage: 'Snow conditions',
    },
    iceConditions: {
        description: 'MIN Report tags for ice conditions',
        defaultMessage: 'Ice conditions',
    },
})

export function useReportTypeOptions() {
    const intl = useIntl()

    return React.useMemo(() => {
        return Object.entries(Names).reduce((options, [type, nameMessage]) => {
            const typeMessage = intl.formatMessage(nameMessage)

            if (type === Types.QUICK) {
                options = Object.entries(QuickReportTags).reduce((options, [tag, tagMessage]) => {
                    const key = type + '-' + tag
                    const message = typeMessage + ' - ' + intl.formatMessage(tagMessage)

                    return options.set(key, message)
                }, options)
            } else {
                options.set(type, typeMessage)
            }

            return options
        }, new Map())
    }, [intl])
}
