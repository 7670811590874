import useSWRImmutable from 'swr/immutable'
import * as Page from '@avcan/prismic/clients/page'

export const useIceClimb = (id, locale) => {
    const key = id ? [id, locale] : null
    const fetcher = (id, locale) => Page.get(id, locale)

    const { data, error } = useSWRImmutable(key, fetcher)

    return {
        data,
        isLoading: !error && !data,
        isError: error,
    }
}
