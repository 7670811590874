import { useRouter } from 'next/router'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import MINClient from '@avcan/utils/api/clients/min'
import SchemasClient from './schemas'
import * as Auth from 'contexts/authentication'
import * as Config from '../config'
import { DateParam } from '@avcan/utils/params'
import * as URLUtils from '@avcan/utils/url'

const client = new MINClient(Config.api, Config.key)

export default client

export function useSubmission(id) {
    const { locale } = useRouter()
    const key = ['submissions', id, locale]

    return useSWR(key, () => client.submission(id, locale))
}

export function useSubmissions(params = {}, options, getToken) {
    const { locale } = useRouter()
    const key = [
        'submissions',
        locale,
        Object.entries(params)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .flat(7),
    ].flat()

    return useSWR(key, () => client.submissions(params, locale, getToken), options)
}

export function useUserSubmissions(params = {}) {
    const { getAccessTokenSilently, user } = Auth.useAuth()
    const userid = user.sub

    return useSubmissions({ ...params, userid }, undefined, getAccessTokenSilently)
}

export function useMINToWinReports(from, to) {
    const { getAccessTokenSilently } = Auth.useAuth()
    const fromdate = DateParam.format(from)
    const todate = DateParam.format(to)
    const key = ['report', fromdate, todate]
    const path = URLUtils.appendParams('report', { fromdate, todate })

    return useSWRImmutable(key, () => client.client.get(path, getAccessTokenSilently))
}

export function useDisplaySchema(version) {
    const { locale } = useRouter()
    const key = ['min', 'schemas', 'display', locale, version]

    return useSWRImmutable(key, () => SchemasClient.getDisplay(version, locale))
}
