import * as Turf from '@turf/helpers'
import { WEATHER_STATION as product } from '@avcan/constants/products'
import * as StationsUtils from '../../lib/array'

export function createSections(stations = [], getKey = getSource) {
    return StationsUtils.groupBy(stations, getKey)
}

export function createFeature({ stationId: id, name, longitude, latitude }) {
    const properties = { id, product, title: name }

    return Turf.point([longitude, latitude], properties, { id })
}

export function createFeatureCollection(data) {
    return Turf.featureCollection(data.map(createFeature))
}

// Utils
export function getSource(station) {
    return station.source
}
